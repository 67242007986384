import axios from 'axios';
import { load } from 'recaptcha-v3';

import {
  AxiosRequestConfig,
  AxiosInstance,
  // AxiosResponse,
  AxiosError,
} from 'axios';

// const url = process.env.VUE_APP_BASE_URL_API;

const conf: AxiosRequestConfig = {
  // baseURL: url,
  timeout: 1000 * 60 * 2,
  headers: {
    'Content-type': 'application/json',
  },
};

const HTTP: AxiosInstance = axios.create(conf);

HTTP.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (config.method == 'post') {
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_SITE_KEY);
    const token = await recaptcha.execute('login');
    if (config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

HTTP.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (error.response && error.response.status === 403) {
      //console.log("adm/showModalError", "Acesso não permitido!");
      // store.dispatch("adm/showModalError", "Acesso não permitido!");
    }
    if (error.response && error.response.status === 500) {
      //console.log(
      //  "adm/showModalError",
      //  "Servidor não responde! Tente mais tarde."
      //);
      // store.dispatch(
      //   "adm/showModalError",
      //   "Servidor não responde! Tente mais tarde."
      // );
    }

    if ((error.response && error.response.status === 408) || error.code === 'ECONNABORTED') {
      //const message = "Tempo excedido para obter os dados! Tente novamente.";
      //console.log("adm/showModalError", message);
      // store.dispatch("adm/showModalError", message);
    }
    if (error.response && error.response.status === 401) {
      // Usuario nao logado
      //console.log("adm/logout");
      // store.dispatch("adm/logout");
    }
    if (!error.response) {
      //console.log("adm/showModalError", "Falha de conexão! Tente novamente.");
      // store.dispatch(
      //   "adm/showModalError",
      //   "Falha de conexão! Tente novamente."
      // );
    }
    return Promise.reject(error);
  }
);

export default HTTP;
