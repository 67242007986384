import HTTP from '@/axios-openAPI';
import Municipio from '../entities/municipio';
import { MunicipioRepository } from './MunicipiosRepository';

export default class MunicipioRepositoryAPI implements MunicipioRepository {
  async findAll(): Promise<Municipio[]> {
    const url = `https://api-localize.auxilio.am.gov.br/api/v1/paises/BR/ufs/AM/localidades?tipoLocalidade=MUNICIPIO`;
    const res = await HTTP.get(url);
    if (res && res.data) {
      return res.data;
    } else {
      return [];
    }
  }
}
