<template>
  <div class="flex w-full flex-col">
    <router-link to="/">
      <div class="bg-innerPage-header flex h-32 w-full flex-wrap content-center lg:h-48"></div>
    </router-link>
    <div class="bg-inner-page">
      <div class="mx-auto px-4 py-10 lg:w-2/3 lg:py-24">
        <router-link to="/" class="mb-5 inline-block rounded-full bg-yellow-400 px-5 py-3 font-bold">
          « Voltar
        </router-link>

        <h1 class="mb-8 text-7xl font-bold uppercase leading-tight">Vídeos</h1>
        <div class="video-container">
          <iframe
            src="https://www.youtube.com/embed/T3JnlxiB6Gw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
