import Address from './entities/address';
import TypeOfStreet from './entities/typeOfStreet';
import AddressRepository from './repositories/addressRepository';

class ServiceAddress {
  constructor(readonly addressRepository: AddressRepository) {}

  async findByCep(cep: string): Promise<Address> {
    return await this.addressRepository.findByCep(cep);
  }
  async getTypesOfStreet(): Promise<TypeOfStreet[]> {
    return await this.addressRepository.getTypesOfStreet();
  }
}

export default ServiceAddress;
