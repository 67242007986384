import HTTP from '@/axios-openAPI';
import Address from '../entities/address';
import TypeOfStreet from '../entities/typeOfStreet';
import AddressRepository from './addressRepository';

export class AddressRepositoryAPI implements AddressRepository {
  async findByCep(cep: string): Promise<Address> {
    const url = `https://api-localize.auxilio.am.gov.br/api/v1/cep/${cep}?siglaUF=AM&tipoLocalidade=MUNICIPIO`;
    const res = await HTTP.get(url);
    return res.data;
  }
  async getTypesOfStreet(): Promise<TypeOfStreet[]> {
    const url = `https://api-localize.auxilio.am.gov.br/api/v1/logradouros/tipos?siglasUFs=AM`;
    const res = await HTTP.get(url);
    return res.data;
  }
}
