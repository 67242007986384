import Address from '../entities/address';
import TypeOfStreet from '../entities/typeOfStreet';
import AddressRepository from './addressRepository';
// import Cep from "../../../mocks/cep.json";

const Cep = {
  cep: 69046751,
  pais: 'BRASIL',
  paisSigla: 'BR',
  uf: 'AMAZONAS',
  ufSigla: 'AM',
  tipoLocalidade: 'MUNICIPIO',
  localidade: 'MANAUS',
  bairro: 'ALVORADA',
  tipoLogradouro: 'RUA',
  logradouro: 'MARILÂNDIA',
  logradouroAlternativo: 'R MARILÂNDIA',
};

const TipoLogradouro = [
  {
    descricao: 'BECO',
  },
  {
    descricao: 'PARQUE',
  },
  {
    descricao: 'RAMAL',
  },
  {
    descricao: 'MARGEM',
  },
  {
    descricao: 'RODOVIA',
  },
  {
    descricao: 'RUA',
  },
  {
    descricao: 'ALAMEDA',
  },
  {
    descricao: 'ESTRADA',
  },
  {
    descricao: 'CAMPO',
  },
  {
    descricao: 'JARDIM',
  },
  {
    descricao: 'CONDOMÍNIO',
  },
  {
    descricao: 'PASSAGEM',
  },
  {
    descricao: 'VIA',
  },
  {
    descricao: 'RAMPA',
  },
  {
    descricao: 'ÁREA',
  },
  {
    descricao: 'CONJUNTO',
  },
  {
    descricao: 'PRAÇA',
  },
  {
    descricao: 'BOSQUE',
  },
  {
    descricao: 'RESIDENCIAL',
  },
  {
    descricao: 'VILA',
  },
  {
    descricao: '6ª AVENIDA',
  },
  {
    descricao: 'AVENIDA',
  },
  {
    descricao: 'BOULEVARD',
  },
  {
    descricao: 'TRAVESSA',
  },
  {
    descricao: 'ACESSO',
  },
  {
    descricao: 'PARQUE RESIDENCIAL',
  },
];

export class AddressRepositoryMock implements AddressRepository {
  findByCep(cep: string): Promise<Address> {
    return new Promise((resolve) => {
      const res: Address = Cep;
      if (cep) {
        resolve(res);
      }
      resolve(res);
    });
  }
  getTypesOfStreet(): Promise<TypeOfStreet[]> {
    return new Promise((resolve) => {
      const res: TypeOfStreet[] = TipoLogradouro;
      resolve(res);
    });
  }
}
