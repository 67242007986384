import HTTP from '@/axios-openAPI';
import Municipio from './entities/municipio';
import { MunicipioRepository } from './repositories/MunicipiosRepository';

class ServiceMunicipios {
  constructor(readonly municipioRepository: MunicipioRepository) {}

  async findAll(): Promise<Municipio[] | []> {
    return await this.municipioRepository.findAll();
  }

  async todos(): Promise<[]> {
    const url = `https://api-localize.auxilio.am.gov.br/api/v1/paises/BR/ufs/AM/localidades?tipoLocalidade=MUNICIPIO`;
    const res = await HTTP.get(url);
    if (res) {
      return res.data;
    } else {
      return [];
    }
  }
}

export default ServiceMunicipios;
