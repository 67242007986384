import { reactive, computed } from 'vue';
import Municipio from './entities/municipio';
import MunicipioRepositoryAPI from './repositories/MunicipioRepositoryAPI';
import ServiceMunicipios from './serviceMunicipios';

interface FaixaCep {
  cepInicio: string;
  cepFim: string;
  tipoFaixa: string;
}

interface Variacao {
  descricao: string;
}

const faixasCep: FaixaCep[] = [];
const variacoes: Variacao[] = [];

const initialState: Municipio[] = [
  {
    nomeUF: '',
    tipo: '',
    nome: '',
    nomeAbreviado: '',
    cep: 0,
    faixasCep: faixasCep,
    variacoes: variacoes,
  },
];

const state = reactive({
  municipios: initialState,
});

const getters = {
  municipios: computed(() => state.municipios),
};

const actions = {
  getMunicipios: async (): Promise<Municipio[]> => {
    const municipioRepository = new MunicipioRepositoryAPI();
    const serviceMunicipios = new ServiceMunicipios(municipioRepository);
    state.municipios = await serviceMunicipios.findAll();
    return state.municipios;
  },
  reset: async (): Promise<void> => {
    state.municipios = initialState;
  },
};

export { state, getters, actions };
