import { reactive, computed } from 'vue';
import Address from './entities/address';
import ServiceAddress from '@/modules/address/serviceAddress';
import { AddressRepositoryMock } from './repositories/addressRepositoryMock';
import { AddressRepositoryAPI } from './repositories/addressRepositoryAPI';
import TypeOfStreet from './entities/typeOfStreet';

const endereco: Address = {
  cep: 0,
  pais: '',
  paisSigla: '',
  uf: '',
  ufSigla: '',
  tipoLocalidade: '',
  localidade: '',
  bairro: '',
  tipoLogradouro: '',
  logradouro: '',
  logradouroAlternativo: '',
};

const tiposLocalidade: TypeOfStreet[] = [];

const initialState = {
  address: endereco,
  tiposLocalidade: tiposLocalidade,
};

const state = reactive(initialState);

const getters = {
  address: computed(() => state.address),
  tiposLocalidade: computed(() => state.tiposLocalidade),
};

const actions = {
  reset: async (): Promise<void> => {
    state.address = endereco;
    state.tiposLocalidade = tiposLocalidade;
  },
  getAddress: async (cep: string): Promise<void> => {
    const addressRepositoryAPI = new AddressRepositoryAPI();
    const serviceAddress = new ServiceAddress(addressRepositoryAPI);
    const res = await serviceAddress.findByCep(cep);
    state.address = res;
  },
  getTypesOfStreet: async (): Promise<void> => {
    // const addressRepositoryAPI = new AddressRepositoryAPI();
    // const serviceAddress = new ServiceAddress(addressRepositoryAPI);
    const addressRepositoryMock = new AddressRepositoryMock();
    const serviceAddress = new ServiceAddress(addressRepositoryMock);
    const res = await serviceAddress.getTypesOfStreet();
    state.tiposLocalidade = res;
  },
};

export { state, getters, actions };
